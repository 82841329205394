import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import PageHelmet from '../components/PageHelmet'

const CvResumeReviewPage = () => (
  <>
    <PageHelmet title="Get a free CV/Resume review" />
    <Container>
      <Row>
        <Col>
          <iframe
            src="//jamesinnes.group/api/reviewembed?utm_source=wikijobs&utm_medium=partner"
            scrolling="no"
            style={{ border: 0, height: '500px', width: '100%' }}
            title="James Innes Group Free CV/Resume Review"
          />
        </Col>
      </Row>
    </Container>
  </>
)

export default CvResumeReviewPage
